import React, { useState } from "react";
import version from "../../version";
import gql from "graphql-tag";
import CaculateForm from "./components/CaculateForm";
import "./styleCaculate.css";
import { Icon, Table } from "antd";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const getDrsRequestQuery = gql`
  query getDrsRequest($income: String) {
    getDrsRequest(income: $income) {
      id
      dsr
      installment
    }
  }
`;

export default () => {
  const [resultRepaymentmo, setRepaymentmo] = useState("");
  const [resultLoanAmount, setloanAmount] = useState("");
  const [resultPactRepaymentmo, setPactRepaymentmo] = useState("");
  const [resultDebtToIncomeRatio, setDebtToIncomeRatio] = useState("");
  const [resultDataDsr, setDataDsr] = useState("");
  const [resultContractPrice, setContractPrice] = useState("");
  const [mapInstallment, setMapInstallment] = useState([]);
  const client = useApolloClient();

  const handleSubmit = async (values) => {
    let income = parseInt(values.income, 10); //ร้ายได้
    let debt = parseInt(values.debt, 10); //หนี้สิน
    let contractPrice = values.ContractPrice
      ? parseInt(values.ContractPrice, 10)
      : 1; //ราคาหน้าสัญญา

    // console.log("income-->", income);
    // console.log("debt-->", debt);
    // console.log("ContractPrice-->", ContractPrice);

    await client
      .query({
        query: getDrsRequestQuery,
        variables: { income: income.toString() },
      })
      .then((response) => {
        const { getDrsRequest } = response.data;
        const data = getDrsRequest;
        // setDsr(0);
        let repaymentmo = income * (parseInt(data[0].dsr) * 0.01) - debt;
        let loanAmount =
          (repaymentmo / parseInt(data[0].installment)) * 1000000;
        let pactRepaymentmo =
          (parseInt(data[0].installment) / 1000000) * contractPrice;
        let DebtToIncomeRatio = ((pactRepaymentmo + debt) / income) * 100;
        // console.log("dsr-->", parseInt(data[0].dsr));
        // console.log("repaymentmo-->", repaymentmo.toFixed(0));
        // console.log("loanAmount-->", loanAmount.toFixed(0));
        // console.log("pactRepaymentmo-->", pactRepaymentmo.toFixed(0));
        // console.log("DebtToIncomeRatio-->", DebtToIncomeRatio.toFixed(0));
        setRepaymentmo(repaymentmo);
        setloanAmount(loanAmount);
        setPactRepaymentmo(pactRepaymentmo);
        setDebtToIncomeRatio(DebtToIncomeRatio.toFixed(0));
        setDataDsr(data[0]);
        setContractPrice(contractPrice);

        const year = [20, 25, 30, 35];

        const result = _.reduce(
          year,
          (c, p) => {
            /*
             * ir   - interest rate per month
             * np   - number of periods (months)
             * pv   - present value
             * fv   - future value
             * type - when the payments are due:
             *        0: end of the period, e.g. end of month (default)
             *        1: beginning of period
             */

            const ir = 0.0465 / 12;
            const np = p * 12;
            const pv = -loanAmount;
            const fv = 0;
            const type = 0;

            if (ir === 0) return -(pv + fv) / np;

            const pvif = Math.pow(1 + ir, np);
            let pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

            if (type === 1) pmt /= 1 + ir;

            const precision = Math.pow(10, -2);
            pmt = Math.ceil(pmt * precision) / precision;

            // console.log(pmt);

            c[p] = pmt.toLocaleString("en-US", {
              // minimumFractionDigits: 2,
              // maximumFractionDigits: 2,
            });

            return c;
          },
          {}
        );
        // console.log("mapInstallment", mapInstallment);
        setMapInstallment(result);
      });
  };

  return (
    <div>
      <nav className="nav">
        <div className="logo">
          <img src="https://api.myorigin.net/static/law/Origin_logo.png" />
        </div>
      </nav>

      <CaculateForm
        onSubmit={(values) => handleSubmit(values)}
        resultRepaymentmo={resultRepaymentmo.toLocaleString("en-US", {
          // minimumFractionDigits: 2,
          // maximumFractionDigits: 2,
        })}
        resultLoanAmount={resultLoanAmount.toLocaleString("en-US", {
          // minimumFractionDigits: 2,
          // maximumFractionDigits: 2,
        })}
        resultPactRepaymentmo={resultPactRepaymentmo.toLocaleString("en-US", {
          // minimumFractionDigits: 2,
          // maximumFractionDigits: 2,
        })}
        resultDebtToIncomeRatio={resultDebtToIncomeRatio.toLocaleString(
          "en-US",
          {
            // minimumFractionDigits: 2,
            // maximumFractionDigits: 2,
          }
        )}
        resultDataDsr={resultDataDsr}
        resultContractPrice={resultContractPrice}
        mapInstallment={mapInstallment}
      />

      <div className="footer">
        <small>
          © Origin Public Company Limited. 2020 All rights reserved. Version
          {version}
        </small>
      </div>
    </div>
  );
};
