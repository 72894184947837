/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/react-hooks";

const Query_GetPaymentCompanyInfo = gql`
  query GetPaymentCompanyInfo($projectId: String!) {
    getPaymentCompanyInfo(projectId: $projectId) {
      id
      projectId
      publicKey
      secretKey
      merchantId
      merchantIdThaiQr
      ch
      createdAt
    }
  }
`;

const Payment = (props) => {
  const client = useApolloClient();
  const [PaymentCompanyInfo, setPaymentCompanyInfo] = React.useState(null);
  const { amount, paymentMethods, projectId, orderId } = props.match.params;

  const paymentUrl =
    "https://kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js";
  // const paymentUrl =
  //   "https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js";

  client
    .query({
      query: Query_GetPaymentCompanyInfo,
      variables: { projectId },
    })
    .then(({ data }) => {
      setPaymentCompanyInfo(data.getPaymentCompanyInfo);
    });

  const attrs = {
    scriptUrl: paymentUrl,
    apiKey: PaymentCompanyInfo?.publicKey,
    skey: PaymentCompanyInfo?.secretKey,
    amount: Number(amount),
    paymentMethods,
    currency: "THB",
    shopName: "Origin connect",
    mid: PaymentCompanyInfo?.merchantId,
    midQr: PaymentCompanyInfo?.merchantIdThaiQr,
    spid: "",
    orderId,
  };

  React.useEffect(() => {
    if (PaymentCompanyInfo !== null) {
      console.log("attrs: ", attrs);
      const script = document.createElement("script");
      script.src = attrs.scriptUrl;
      script.setAttribute("data-apikey", attrs.apiKey);
      script.setAttribute("data-amount", attrs.amount.toFixed(2));
      script.setAttribute("data-payment-methods", attrs.paymentMethods);
      if (attrs.paymentMethods === "card") {
        script.setAttribute("data-currency", attrs.currency);
        script.setAttribute("data-name", attrs.shopName);
        script.setAttribute("data-mid", attrs.mid);
        if (attrs.spid !== "") {
          script.setAttribute("data-smartpay-id", attrs.spid);
          script.setAttribute("data-term", "10");
        }
      }
      if (attrs.paymentMethods === "qr") {
        script.setAttribute("data-order-id", attrs.orderId);
        script.setAttribute("data-mid", attrs.midQr);
      }
      // script.setAttribute("data-show-button", "false");
      script.type = "text/javascript";
      script.async = true;
      script.onload = (ev) => {
        if (checkoutForm) {
          window.KPayment.create();
          let buttonForm = document.getElementsByClassName("pay-button");
          buttonForm[0].click();
        }
      };
      let checkoutForm = document.getElementById(`checkout-form`);
      checkoutForm.appendChild(script);

      checkoutForm.addEventListener("submit", async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        let token = formData.get("token");
        if (paymentMethods === "card") {
          const input = {
            amount: Number(amount),
            paymentMethods,
            skey: attrs.skey,
            token,
            mid: attrs.mid,
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(input));
        }
        if (paymentMethods === "qr") {
          const input = {
            amount: Number(amount),
            paymentMethods,
            skey: attrs.skey,
            orderId: attrs.orderId,
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(input));
        }
      });
      return () => {
        checkoutForm.innerHTML = "";
      };
    }
  }, [PaymentCompanyInfo]);

  return (
    <>
      <form id="checkout-form"></form>
    </>
  );
};

export default Payment;
