import gql from 'graphql-tag';

export default gql`
  mutation($username: String!, $password: String!) {
    customerLogin(username: $username, password: $password) {
      token
      refreshToken
    }
  }
`;
