/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import getRmsContractQuery from "../schema/getRmsContractQuery";

class SelectProject extends Component {
  state = {
    contracts: [],
  };

  componentWillMount = async () => {
    this.props.client
      .query({
        query: getRmsContractQuery,
        variables: { customerId: this.props.customerId },
      })
      .then((result) => {
        const contracts = result.data.getRmsCustomerContractMy.map(
          (contract) => {
            // console.log(contract);
            return {
              id: contract.id,
              customerId: contract.customer && contract.customer.memberId,
              projectId: contract.project && contract.project.id,
              projectName: contract.project && contract.project.name,
              unitNumber: contract.unit && contract.unit.unitNumber,
              directType: contract.recurring && contract.recurring.directType,
            };
          }
        );

        this.setState({ contracts });

        if (contracts.length > 0) {
          this.props.changeContract(contracts[0]);
        }
      })
      .catch((e) => console.log(e));
  };

  render() {
    const contracts = this.state.contracts;

    return (
      <aside className="menu">
        <p className="menu-label">โครงการ / ห้อง</p>
        <ul className="menu-list">
          {contracts.map((contract) => (
            <li key={contract.id}>
              <a
                className={
                  this.props.selectContract.id === contract.id
                    ? "is-active"
                    : ""
                }
                onClick={() => this.props.changeContract(contract)}
              >
                {contract.projectName} / {contract.unitNumber}
              </a>
            </li>
          ))}
        </ul>
      </aside>
    );
  }
}

export default withApollo(SelectProject);
