import React, { Component } from 'react';
import classNames from 'classnames';
import { SubmissionError } from 'redux-form';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import EditCrmForm from './EditCrmForm';

const requestEditCustomerInfoMutation = gql`
  mutation($input: inputEditCustomerInfo) {
    requestEditCustomerInfo(input: $input) {
      id
      type
      customerId
    }
  }
`;

const getCrmContractMyQuery = gql`
  query getRmsCrmContactMy {
    getRmsCrmContactMy {
      id
      customerId
      citizenId
      passportId
      birthdate
      namePrefix {
        id
        text
      }
      firstname
      lastname
      nickname
      gender
      nationality {
        id
        text
      }
      race {
        id
        text
      }

      exigentFirstName
      exigentLastName
      exigentTel
      exigentRelation

      tel1
      tel2
      email

      addressDocument {
        id
        addressNo
        moo
        soi
        village
        road
        subDistrict
        district
        province
        zipCode
        country
      }

      addressRegister {
        id
        addressNo
        moo
        soi
        village
        road
        subDistrict
        district
        province
        zipCode
        country
      }

      addressWork {
        id
        addressNo
        moo
        soi
        village
        road
        subDistrict
        district
        province
        zipCode
        country
      }

      dmFlag
      smsFlag
    }

    getEditCrmStatus {
      id
      type
      editStatus
    }
  }
`;

export default class EditCrmModal extends Component {
  render() {
    const {
      show,
      handleClose,
      customerData: {
        id,
        namePrefix,
        firstname,
        lastname,
        tel1,
        tel2,
        addressDocument: {
          addressNo,
          village,
          moo,
          soi,
          road,
          subDistrict,
          district,
          province,
          country,
          zipCode,
        },
      },
    } = this.props;

    const modalClass = classNames({
      modal: true,
      'is-active': show,
    });

    const initialValues = {
      customerId: id,
      customerName: `${
        namePrefix ? namePrefix.text : ''
      }${firstname} ${lastname}`,
      tel1,
      tel2,
      addressNo,
      village,
      moo,
      soi,
      road,
      subDistrict,
      district,
      province,
      country,
      zipCode,
    };

    return (
      <div className={modalClass}>
        <div className="modal-background" onClick={() => handleClose()} />
        <div className="modal-card">
          <header className="modal-card-head">
            <div className="modal-card-title">แก้ไขข้อมูลส่วนตัว</div>
            <button
              className="delete"
              aria-label="close"
              onClick={() => handleClose()}
            />
          </header>
          <section className="modal-card-body">
            <Mutation
              mutation={requestEditCustomerInfoMutation}
              refetchQueries={() => [
                {
                  query: getCrmContractMyQuery,
                },
              ]}
            >
              {requestEditCustomerInfoMutation => {
                return (
                  <EditCrmForm
                    initialValues={initialValues}
                    handleClose={() => handleClose()}
                    onSubmit={values => {
                      const input = {
                        ...values,
                        type: '1',
                      };

                      return requestEditCustomerInfoMutation({
                        variables: { input },
                      })
                        .then(() => {
                          handleClose();
                        })
                        .catch(error => {
                          throw new SubmissionError({
                            _error: error.message.split(':')[1],
                          });
                        });
                    }}
                  />
                );
              }}
            </Mutation>
          </section>
        </div>
      </div>
    );
  }
}
