import gql from "graphql-tag";

export default gql`
  query getRmsPayment($contractId: String!) {
    getRmsCustomerContractMyById(id: $contractId) {
      id
      sendName
      sendAddress
      project {
        id
        projectId
        name
        nameEng
        projectType
      }
      downPayment {
        id
        contractId
        termId
        termName
        termDueDate
        paymentDate
        termAmount
        amount
        receiptId
        receiptNumber
        paymentType
      }
    }
  }
`;
