import React, { Component } from 'react';
import styled from 'styled-components';

const ThaiWord = styled.span`
  color: #666666;
`;

const EngWord = styled.span`
  color: #4472c4;
`;

const JapanWord = styled.span`
  color: #006600;
`;

const EmailTag = styled.a`
  color: #4472c4;
`;

class PaymentNotice extends Component {
  render() {
    return (
      <div className="content is-small">
        <p>
          <ThaiWord>
            1. หากมีข้อสงสัยในรายการชำระเงินของท่าน กรุณาติดต่อ Call Center &nbsp;
          </ThaiWord>
          020 300 000 กด 1
          จันทร์ – ศุกร์ เวลา 9.00 – 18.00 น.
          <br />
          <EngWord>
            1. If you have any issues regarding usage of this system, please
            contact Call Center 020 300 000 press 1
            (Monday-Friday 9:00 – 18:00)
          </EngWord>
          <br />
          <JapanWord>
            1. ご不明な点等がございましたら、+66-84-4270099 又は{' '}
            <EmailTag href="mailto:wisanee.s@origin.co.th">
              wisanee.s@origin.co.th
            </EmailTag>{' '}
            までご連絡くださいませ。（月曜日～金曜日０９：００～１８：００まで）
          </JapanWord>
        </p>
        <p>
          <ThaiWord>
            2. ท่านจะได้รับใบเสร็จรับเงินภายใน 10 วันทำการ
            นับจากวันที่ท่านชำระเงิน
          </ThaiWord>
          <br />
          <EngWord>
            2. You will receive the receipt within 10 business days after the
            payment is confirmed.
          </EngWord>
          <br />
          <JapanWord>
            2.お支払いの確認ができてから１０日間以内に、領収書をお送りいたします。
          </JapanWord>
        </p>
      </div>
    );
  }
}

export default PaymentNotice;
