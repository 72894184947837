import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { from, Observable } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import mainUrl from "./mainUrl";
import { logout } from "../auth";

export const apiAuthToken = "hHJMpLhoCJ6xEGPVuKr7gqd8zFZXHR13";

const httpLink = new HttpLink({
  uri: `${mainUrl}/graphql`
});

const genNewToken = async refreshToken => {
  return new Promise(async (success, fail) => {
    try {
      const requestRefreshToken = await fetch(`${mainUrl}/token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiAuthToken}`
        },

        body: JSON.stringify({
          refreshToken
        })
      });

      const jsonToken = await requestRefreshToken.json();
      return success(jsonToken.token);
    } catch (e) {
      console.log(e);
      return fail({});
    }
  });
};

const authLink = setContext(async (request, previousContext) => {
  const { headers } = previousContext;
  const token = localStorage.getItem("token");

  if (headers) {
    return {
      headers: {
        ...headers
      }
    };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${apiAuthToken}`,
      "x-token": token || null
    }
  };
});

const linkErr = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ extensions, message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      const notAuth = graphQLErrors.reduce((pre, cur) => {
        if (
          cur.message === "Not authenticated" ||
          cur.message === "Not Authorised!" ||
          cur.message ===
            "Access denied! You need to be authorized to perform this action!" ||
          cur.message.includes("Not authenticated")
        ) {
          return true;
        }

        return pre;
      }, false);

      if (notAuth) {
        return new Observable(observer => {
          const refreshToken = localStorage.getItem("refreshToken");

          genNewToken(refreshToken)
            .then(refreshResponse => {
              localStorage.setItem("token", refreshResponse);
              operation.setContext(({ headers = {} }) => {
                return {
                  headers: {
                    ...headers,
                    Authorization: `Bearer ${apiAuthToken}`,
                    "x-token": refreshResponse
                  }
                };
              });
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              };

              forward(operation).subscribe(subscriber);
            })
            .catch(error => {
              logout();
              observer.error(error);
            });
        });
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

export const client = new ApolloClient({
  link: from([linkErr, authLink, httpLink]),
  cache: new InMemoryCache()
});
