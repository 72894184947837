import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/fontawesome-free-solid';

import { renderInput, renderCheckBox } from 'components/reduxForm/normalForm';
import { required, email } from 'components/reduxForm/validate';

const Topic = ({ text }) => {
  return (
    <div>
      <h4>{text}</h4>
      <hr />
    </div>
  );
};

const EditRemForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  initialValues,
  eReceiptValue,
  editStatus,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {editStatus === '0' && (
        <article className="message is-warning">
          <div className="message-body">
            <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
            อยู่ในระหว่างแก้ไขข้อมูล หากท่านต้องการแก้ไขเพิ่มเติมกรุณาติดต่อ
            call center ได้ที่ 020 300 000
          </div>
        </article>
      )}
      <Topic text="ที่อยู่สำหรับจัดส่งเอกสาร" />
      <div className="columns">
        <div
          className="column is-half"
          style={{ borderRight: '2px solid whitesmoke' }}
        >
          <p className="has-text-grey has-text-weight-bold">
            ที่อยู่ปัจจุบัน :
          </p>
          <p className="has-text-black-bis">{initialValues.sendAddress}</p>
        </div>
        <div className="column is-half">
          <p className="has-text-grey has-text-weight-bold">
            ที่อยู่ที่ต้องการเปลี่ยน :
          </p>
          <div className="columns is-multiline">
            <div className="column is-4">
              <Field
                name="addressNo"
                label="เลขที่"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-5">
              <Field
                name="village"
                label="หมู่บ้าน/อาคาร"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-3">
              <Field
                name="moo"
                label="หมู่"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-3">
              <Field
                name="soi"
                label="ซอย"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-5">
              <Field
                name="road"
                label="ถนน"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-4">
              <Field
                name="subDistrict"
                label="ตำบล/แขวง"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-4">
              <Field
                name="district"
                label="อำเภอ/เขต"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-4">
              <Field
                name="province"
                label="จังหวัด"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-4">
              <Field
                name="country"
                label="ประเทศ"
                type="text"
                size="is-normal"
                component={renderInput}
              />
            </div>
            <div className="column is-4">
              <Field
                name="zipCode"
                label="รหัสไปรษณีย์"
                type="number"
                size="is-normal"
                component={renderInput}
              />
            </div>
          </div>
        </div>
      </div>

      <Topic text="E-Reciept" />
      <Field
        boxName="ต้องการรับใบเสร็จทางอีเมลล์"
        name="eReceipt"
        type="checkbox"
        component={renderCheckBox}
      />
      {eReceiptValue && (
        <div className="column is-half">
          <Field
            name="eReceiptMail"
            label="อีเมลล์สำหรับจัดส่งเอกสาร"
            component={renderInput}
            validate={[required, email]}
          />
        </div>
      )}
      {error && (
        <article className="message is-danger">
          <div className="message-body">{error}</div>
        </article>
      )}
      {!editStatus && (
        <button
          type="submit"
          className="button is-success"
          disabled={pristine || submitting}
        >
          ยืนยัน
        </button>
      )}
    </form>
  );
};

const withReduxForm = reduxForm({
  form: 'EditRemForm',
  enableReinitialize: true,
})(EditRemForm);

const selector = formValueSelector('EditRemForm');

const ConnectWithSelectorForm = connect(state => {
  const eReceiptValue = selector(state, 'eReceipt');

  return {
    eReceiptValue,
  };
})(withReduxForm);

export default ConnectWithSelectorForm;
