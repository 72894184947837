import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { SubmissionError } from 'redux-form';

import gql from 'graphql-tag';

import EditRemForm from './EditRemForm';

const requestEditCustomerByContract = gql`
  mutation($input: inputEditCustomerInfo) {
    requestEditCustomerByContract(input: $input) {
      id
      type
      customerId
    }
  }
`;

const getRmsCustomerContractMyById = gql`
  query getRmsCustomerContractMyById($id: String!) {
    getRmsCustomerContractMyById(id: $id) {
      id
      sendAddress
      eReceiptMail
      eReceipt
      customer {
        id
        customerId
        memberId
        firstname
        lastname
      }
    }

    getEditRemStatus(contractId: $id) {
      id
      type
      editStatus
    }
  }
`;

export default class EditRemLayout extends Component {
  render() {
    return (
      <Query
        query={getRmsCustomerContractMyById}
        variables={{ id: this.props.id }}
      >
        {({ data, loading }) => {
          if (loading) return false;
          if (!data?.getRmsCustomerContractMyById) return false;

          const {
            id,
            eReceipt,
            eReceiptMail,
            sendAddress,
            customer,
          } = data.getRmsCustomerContractMyById;

          const editStatus =
            data.getEditRemStatus && data.getEditRemStatus.editStatus;

          const initialValues = {
            customerId: customer.memberId,
            customerName: `${customer.firstname} ${customer.lastname}`,
            contractId: id,
            eReceipt,
            eReceiptMail,
            sendAddress,
          };

          return (
            <Mutation
              mutation={requestEditCustomerByContract}
              refetchQueries={() => [
                {
                  query: getRmsCustomerContractMyById,
                  variables: { id },
                },
              ]}
            >
              {requestEditCustomerByContract => {
                return (
                  <EditRemForm
                    editStatus={editStatus}
                    initialValues={initialValues}
                    onSubmit={values => {
                      const input = {
                        ...values,
                        type: '2',
                      };

                      return requestEditCustomerByContract({
                        variables: { input },
                      })
                        .then(() => {
                          console.log(input);
                        })
                        .catch(error => {
                          throw new SubmissionError({
                            _error: error.message.split(':')[1],
                          });
                        });
                    }}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}
