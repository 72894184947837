import React from 'react';
import styled from 'styled-components';

const Highlight = styled.span`
  color: #ea153c;
  font-weight: bold;
`;

export const PasswordSuggestion = props => {
  return (
    <nav className="panel">
      <p className="panel-heading has-text-white-bis has-text-centered has-text-weight-bold is-size-6" style={{ backgroundColor: '#bc1030'}}>
        รหัสผ่านของท่านคือ วันเดือนปีเกิด (ค.ศ.) ตามรูปแบบ ddMmmyyyy
      </p>
      <div className="panel-block has-background-grey-lighter" style={{ fontSize: '14px' }}>
        <div className="column is-two-thirds">
          <p>
            <Highlight>ex. Your birthday is on 9th May 1995.</Highlight>
            <br />
            เช่น วันเกิดของท่าน '9 พฤษภาคม 1995'
            <br />
            dd:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;วันเกิด '09'
            <br />
            Mmm:&nbsp;&nbsp;&nbsp;3 ตัวแรกของเดือนเกิด 'May'
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
            อักษรตัวแรกเป็นพิมพ์ใหญ่ )
            <br />
            yyyy:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ปีเกิด (ค.ศ.) '1995'
          </p>
        </div>
        <div className="column is-one-third has-text-centered has-text-weight-bold is-size-6">
          <p>
            <Highlight>Your password is</Highlight>
            <br />
            <Highlight>09May1995</Highlight>
            <br />
            <br />
            รหัสของท่านคือ
            <br />
            09May1995
          </p>
        </div>
      </div>
    </nav>
  );
};
