import decode from 'jwt-decode';

// Actions
const USER_LOGGED_IN = 'check-payment/user/USER_LOGGED_IN';
const CLEAR_AUTH = 'check-payment/user/CLEAR_AUTH';

const checkAuth = (token, refreshToken) => {
  if (!token || !refreshToken) {
    return { user: null, userAuth: [] };
  }

  try {
    const { exp } = decode(refreshToken);
    const { user, userAuth, employee } = decode(token);

    if (exp < new Date().getTime() / 1000) {
      return { user: null, userAuth: [] };
    }

    return { user, userAuth, employee };
  } catch (e) {
    return { user: null, userAuth: [] };
  }
};

const token = localStorage.getItem('token');
const refreshToken = localStorage.getItem('refreshToken');
// const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiQy0wMDAxODQ0Iiwic3RhdHVzIjoiOSJ9LCJpc0N1c3RvbWVyIjp0cnVlLCJpYXQiOjE1MTMxNzc0NDYsImV4cCI6MTUxMzc4MjI0Nn0.jU36aAp-QyuEX2-aHzEP7YLGU2flMmLK3io6ZXsVli4';
const temp = checkAuth(token, refreshToken);

const initialState = {
  token,
  refreshToken,
  data: temp.user,
  userAuth: temp.userAuth,
  employeeId: temp.employee && temp.employee.employeeId,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      const { token, refreshToken } = action.payloads;
      const { user, userAuth, employee } = checkAuth(token, refreshToken);

      return {
        token,
        refreshToken,
        data: user,
        userAuth,
        employeeId: employee && employee.employeeId,
      };
    case CLEAR_AUTH:
      return {
        token: null,
        refreshToken: null,
        data: null,
        userAuth: [],
      };
    // do reducer stuff
    default:
      return state;
  }
}

// Action Creators
export function clearAuth() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  return { type: CLEAR_AUTH };
}

export function login(token, refreshToken) {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  return { type: USER_LOGGED_IN, payloads: { token, refreshToken } };
}
