// import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import decode from 'jwt-decode';
import store from './store';
import { clearAuth } from 'reducers/user';

const checkAuth = dispatch => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!token || !refreshToken) {
    return false;
  }

  try {
    const { exp } = decode(refreshToken);

    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => {
    return checkAuth() && state.user.data != null;
  },
  // authenticatingSelector: state => state.user.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const userIsAdmin = connectedRouterRedirect({
  authenticatedSelector: state =>
    state.user.userAuth.filter(data => data === 'admin')
      .length !== 0
      ? true
      : false,
  redirectPath: '/',
  wrapperDisplayName: 'userIsAdmin',
  allowRedirectBack: false,
});

export function logout() {
  const { dispatch } = store;
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  dispatch(clearAuth());
}
