import React, { Component } from 'react';
import MainLayout from 'components/layout/MainLayout';
import CSMenu from './components/CSMenu';
import CSLayout from './components/CSLayout';
import LoanStatus from './components/LoanStatus';
// import RemDisplay from './components/RemDisplay';

export default class CS extends Component {
  state = {
    no: '1',
  };

  handleChangeMenu(no) {
    this.setState({ no });
  }

  checkDisplay(no) {
    switch (no) {
      case '1':
        return <CSLayout />;
      case '2':
        return <LoanStatus />;
      default:
        break;
    }
  }

  render() {
    const { no } = this.state;
    return (
      <MainLayout>
        <div className="container">
          <div className="columns">
            <div className="column is-one-fifth">
              <CSMenu
                no={this.state.no}
                handleChangeMenu={no => this.handleChangeMenu(no)}
              />
            </div>
            <div className="column">{this.checkDisplay(no)}</div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
