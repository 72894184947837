import React from 'react';
import errorBlock from './errorBlock';
import labelBlock from './labelBlock';

export function renderInput(e) {
  const {
    input,
    label,
    type,
    meta: { touched, error, warning },
    size,
    style,
    disabled,
  } = e;

  const hasError = touched && error ? ' is-danger' : '';

  return (
    <div className="field">
      {labelBlock(label,size,style)}
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              className={`input${hasError}`}
              id={input.name}
              {...input}
              type={type}
              disabled={disabled}
            />
          </div>
          {errorBlock(touched, error, warning)}
        </div>
      </div>
    </div>
  );
}

export function renderTextBox(e) {
  const {
    input,
    label,
    type,
    meta: { touched, error, warning },
    size,
    style,
    disabled,
  } = e;

  const hasError = touched && error ? ' is-danger' : '';

  return (
    <div className="field">
      {labelBlock(label,size,style)}      
      <div className="field-body">
        <div className="field">
          <div className="control">
            <textarea
              className={`textarea${hasError}`}
              id={input.name}
              {...input}
              type={type}
              disabled={disabled}
            />
          </div>
          {errorBlock(touched, error, warning)}
        </div>
      </div>
    </div>
  );
}

export function renderCheckBox(e) {
  const {
    input,
    label,
    type,
    boxName,
    meta: { touched, error, warning },
    size,
    style,
    disabled,
  } = e;

  return (
    <div className="field">
      {labelBlock(label,size,style)}      
      <div className="field-body">
        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input
                id={input.name}
                type={type}
                {...input}
                disabled={disabled}
              />{' '}
              {boxName}
            </label>
          </div>
          {errorBlock(touched, error, warning)}
        </div>
      </div>
    </div>
  );
}
