/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
// import ProfileGreeting from 'components/layout/ProfileGreeting';
import SelectProject from "./components/SelectProject";
import PaymentTable from "./components/PaymentTable";
import PaymentNotice from "./components/PaymentNotice";
import bannerManual from "./img/banner01.jpg";
import bannerGuarantee from "./img/banner02.jpg";
import bannerParking from "./img/banner03.jpg";

const url = "https://api.myorigin.net/static/manual";

const dataSource = {
  // PSE04: [
  //   { id: 'manual', filename: 'manual.pdf', banner: bannerManual },
  //   { id: 'guarantee', filename: 'guarantee.pdf', banner: bannerGuarantee },
  //   { id: 'parking', filename: 'parking.pdf', banner: bannerParking },
  // ],
  KNB06: [
    { id: "manual", filename: "manual.pdf", banner: bannerManual },
    { id: "guarantee", filename: "guarantee.pdf", banner: bannerGuarantee },
    { id: "parking", filename: "parking.pdf", banner: bannerParking },
  ],
};

class PaymentLayout extends Component {
  state = {
    selectContract: {},
    selectedTab: "table",
  };

  changeContract(contractId) {
    this.setState({ selectContract: contractId });
  }

  setSelectedTab(selectedTab) {
    this.setState({ selectedTab });
  }

  render() {
    const { selectedTab, selectContract } = this.state;
    // console.log(selectContract);

    return (
      <div className="container">
        <div className="columns">
          <div className="column is-one-fifth">
            <SelectProject
              selectContract={this.state.selectContract}
              changeContract={(contractId) => this.changeContract(contractId)}
            />
          </div>
          <div className="column">
            <div className="tabs">
              <ul>
                <li className={selectedTab === "table" && "is-active"}>
                  <a onClick={() => this.setSelectedTab("table")}>
                    <span className="icon is-small">
                      <i className="fa fa-table" aria-hidden="true"></i>
                    </span>
                    <span>ตารางการชำระเงิน</span>
                  </a>
                </li>
                <li className={selectedTab === "document" && "is-active"}>
                  <a onClick={() => this.setSelectedTab("document")}>
                    <span className="icon is-small">
                      <i className="fa fa-file" aria-hidden="true"></i>
                    </span>
                    <span>คู่มือพักอาศัย</span>
                  </a>
                </li>
              </ul>
            </div>

            {selectedTab === "table" && (
              <PaymentTable
                contractId={this.state.selectContract.id || ""}
                directType={this.state.selectContract.directType || ""}
                selectContract={this.state.selectContract}
              />
            )}

            {selectedTab === "document" &&
              dataSource[selectContract.projectId] && (
                <div className="columns is-desktop is-centered">
                  <div className="column is-half">
                    {dataSource[selectContract.projectId].map((d) => (
                      <div style={{ marginBottom: "12px" }}>
                        <a
                          key={d.id}
                          href={`${url}/${d.filename}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={d.banner}
                            className="responsive-image"
                            alt="Null"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <PaymentNotice />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(PaymentLayout);
