import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import EditLoanForm from './EditLoanForm';
import moment from 'moment';
import getRmsContractQuery from '../schema/getRmsContract';

const getLoanQuery = gql`
query getLoan($ContractID: String) {
  getLoan(
    ContractID: $ContractID
  ) {
    ContractID
    jobType
    position
    salary
    otherIncome
    homeLoan
    carLoan
    creditLoan
    creditDebt
    addressNo
    village
    moo
    soi
    road
    subDistrict
    district
    province
    country
    zipCode
    loanType
    relationType
    fullName2
    birthDate2
    citizenId2
    tel2
    email2
    homeAddress2
    currentAddress2
    position2
    salary2
    otherIncome2
    homeLoan2
    carLoan2
    creditLoan2
    creditDebt2
    addressNo2
    village2
    moo2
    soi2
    road2
    subDistrict2
    district2
    province2
    country2
    zipCode2
  }
}
`;

class CSLayout extends Component {
  state = {
    selectContract: '',
    data: [],
    initialValues: {
      loanType: '0',
    },
    isSubmit: false,
  };

  async handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const loan = await this.getLoanData(value);
    let loanRes = {...loan.getLoan}
    
    if(loanRes){
      if(!loanRes.loanType){
        loanRes.loanType = '0';
      }
      if(loanRes.birthDate2){
        loanRes.birthDate2 = moment(loanRes.birthDate2).format('DD-MM-YYYY')
      }
    }
    
    this.setState({
      initialValues: {...loanRes},
      [name]: value,
    });
  }

  async componentDidMount() {

    const data = await this.fetchData();

    const ContractID = this.state.selectContract === ''? data.getRmsCustomerContractMy[0].id
    : this.state.selectContract;

    const loan = await this.getLoanData(ContractID);
    let loanRes = {...loan.getLoan}
    
    if(loanRes){
      if(!loanRes.loanType){
        loanRes.loanType = '0';
      }
      if(loanRes.birthDate2){
        loanRes.birthDate2 = moment(loanRes.birthDate2).format('DD-MM-YYYY')
      }
    }
    
    this.setState({
      selectContract:ContractID,
      data: data.getRmsCustomerContractMy, //.filter(o => o.project.id === 'PSE04'),
      initialValues: {...loanRes},
    });

  }

  async fetchData() {
    return this.props.client
    .query({
      query: getRmsContractQuery
    })
    .then(({ data })=> {
      return data;
    });
  }

  async getLoanData(ContractID) {
    return this.props.client
    .query({
      query: getLoanQuery,
      variables: { ContractID },
    })
    .then(({ data }) => {
      return data;
    });
  }

   async handleSubmit(values){

    const ContractID = this.state.selectContract;

    if(values.birthDate2 !== ''){
      values.birthDate2 = moment(values.birthDate2,"DD-MM-YYYY")
    }else{
      values.birthDate2 = null;
    }

    const res = await this.props.client.mutate({
        mutation: gql`
        mutation($ContractID: String, 
    $jobType: String, 
    $position: String, 
    $salary: String, 
    $otherIncome: String, 
    $homeLoan: String, 
    $carLoan: String, 
    $creditLoan: String, 
    $creditDebt: String, 
    $addressNo: String, 
    $village: String, 
    $moo: String, 
    $soi: String, 
    $road: String, 
    $subDistrict: String, 
    $district: String, 
    $province: String, 
    $country: String, 
    $zipCode: String, 
    $loanType: String, 
    $relationType: String, 
    $fullName2: String, 
    $birthDate2: String, 
    $citizenId2: String, 
    $tel2: String, 
    $email2: String, 
    $homeAddress2: String, 
    $currentAddress2: String, 
    $position2: String, 
    $salary2: String, 
    $otherIncome2: String, 
    $homeLoan2: String, 
    $carLoan2: String, 
    $creditLoan2: String, 
    $creditDebt2: String, 
    $addressNo2: String, 
    $village2: String, 
    $moo2: String, 
    $soi2: String, 
    $road2: String, 
    $subDistrict2: String, 
    $district2: String, 
    $province2: String, 
    $country2: String, 
    $zipCode2: String) {
            addLoan(ContractID: $ContractID, 
    jobType: $jobType, 
    position: $position, 
    salary: $salary, 
    otherIncome: $otherIncome, 
    homeLoan: $homeLoan, 
    carLoan: $carLoan, 
    creditLoan: $creditLoan, 
    creditDebt: $creditDebt, 
    addressNo: $addressNo, 
    village: $village, 
    moo: $moo, 
    soi: $soi, 
    road: $road, 
    subDistrict: $subDistrict, 
    district: $district, 
    province: $province, 
    country: $country, 
    zipCode: $zipCode, 
    loanType: $loanType, 
    relationType: $relationType, 
    fullName2: $fullName2, 
    birthDate2: $birthDate2, 
    citizenId2: $citizenId2, 
    tel2: $tel2, 
    email2: $email2, 
    homeAddress2: $homeAddress2, 
    currentAddress2: $currentAddress2, 
    position2: $position2, 
    salary2: $salary2, 
    otherIncome2: $otherIncome2, 
    homeLoan2: $homeLoan2, 
    carLoan2: $carLoan2, 
    creditLoan2: $creditLoan2, 
    creditDebt2: $creditDebt2, 
    addressNo2: $addressNo2, 
    village2: $village2, 
    moo2: $moo2, 
    soi2: $soi2, 
    road2: $road2, 
    subDistrict2: $subDistrict2, 
    district2: $district2, 
    province2: $province2, 
    country2: $country2, 
    zipCode2: $zipCode2){
      ContractID
    } 
        }
      `,
        variables: { ...values, ContractID},
      }).catch(e => {
          console.log(e);
      })

    // console.log(res);
      this.setState({ isSubmit: true });
    //window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
  }

  render() {
    const allMyContract = this.state.data;

    return ( this.state.isSubmit? (<div className="notification is-success" style={{ backgroundColor: '#72d0eb', color: '#454545'}}>บันทึกข้อมูลเรียบร้อย ทางเจ้าหน้าที่สัมพันธ์จะรีบดำเนินการติดต่อกลับไปค่ะ</div>) : (
      <div className="content">
        <div className="columns">
          <div className="column is-2 has-text-right">
            <strong>โครงการ :</strong>
          </div>
          <div className="column">
            <div className="select">
              <select
                name="selectContract"
                value={this.state.selectContract}
                onChange={e => this.handleChange(e)}
              >
                {allMyContract.map(data => (
                  <option value={data.id} key={data.id}>{`${
                    data.project.name
                  } / ${data.unit.unitNumber}`}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {this.state.selectContract.includes('KEN08')? <EditLoanForm initialValues={this.state.initialValues} onSubmit={this.handleSubmit.bind(this)} /> : <div></div>} 
      </div>
      )
    );
  }
}

const withQL = withApollo(CSLayout);

export default withQL;
