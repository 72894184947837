import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { renderInput } from 'components/reduxForm/normalForm';
import { required, email } from 'components/reduxForm/validate';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import BankDisplay from './BankDisplay';
import 'react-datepicker/dist/react-datepicker.css';

const renderDatePicker = e => {
  const {
    input,
    meta: { touched, error, warning, submitting },
    showYearDropdown,
    disabled,
    size,
  } = e;

  const hasError = touched && error ? ' has-error' : '';

  return (
    <div className="field">
    <div className="label is-normal"><label className="label">วันเดือนปีเกิด</label></div>
    <div className="field-body">
      <div className={`control`}>
        <DatePicker
          {...input}
          className={`input`}
          dateFormat="DD-MM-YYYY"
          selected={input.value ? moment(input.value, 'DD-MM-YYYY') : null}
          onChange={input.onChange}
          showYearDropdown={showYearDropdown}
          disabled={disabled || submitting}
          autoComplete={`off`}
        />
        {touched &&
          ((error && <span className="help-block">{error}</span>) ||
            (warning && <span className="help-block">{warning}</span>))}
      </div>
    </div>
    </div>
  );
};

const Topic = ({ text }) => {
  return (
    <div>
      <h4>{text}</h4>
      <hr />
    </div>
  );
};

const EditLoanForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  initialValues,
  loanType,
  editStatus,
}) => {

// console.log(loantype)

return (
<form onSubmit={handleSubmit}>
<Topic text="ข้อมูลการทำงาน" />         
<div className="columns">
<div className="column is-half">
<p className="has-text-grey has-text-weight-bold">
  ข้อมูลการทำงาน :
</p>
<div className="columns is-multiline">
  <div className="column is-6">
    <div>
    <label style={{fontWeight: 'bold'}}>อาชีพ</label>
    </div>
    <div style={{paddingTop: '8px'}}>
    <Field name="jobType" type="select" component="select" style={{width: '100%', height: '34px'}}>
      <option />
      <option value="Self-employed">ธุรกิจส่วนตัว</option>
      <option value="Employee">รายได้ประจำ</option>
    </Field>
    </div>
  </div>
  <div className="column is-6">
    <Field
      name="position"
      label="ตำแหน่งงาน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="salary"
      label="รายได้ประจำ / เดือน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="otherIncome"
      label="รายได้อื่นๆ"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
<p className="has-text-grey has-text-weight-bold">
    ค่าใช้จ่าย
</p>
<div className="columns is-multiline">
  <div className="column is-6">
    <Field
      name="homeLoan"
      label="สินเชื่อบ้าน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="carLoan"
      label="สินเชื่อรถยนต์"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="creditLoan"
      label="สินเชื่อบัตรเครดิต"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="creditDebt"
      label="บัตรเครดิต"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
<p className="has-text-grey has-text-weight-bold">
    สถานที่ทำงาน
</p>
<div className="columns is-multiline">
  <div className="column is-5">
    <Field
      name="addressNo"
      label="เลขที่"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="village"
      label="หมู่บ้าน/อาคาร"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-3">
    <Field
      name="moo"
      label="หมู่"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-3">
    <Field
      name="soi"
      label="ซอย"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-5">
    <Field
      name="road"
      label="ถนน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="subDistrict"
      label="ตำบล/แขวง"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="district"
      label="อำเภอ/เขต"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="province"
      label="จังหวัด"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="country"
      label="ประเทศ"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="zipCode"
      label="รหัสไปรษณีย์"
      type="number"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
<Topic text="ลูกค้าแจ้งความประสงค์" />
<div className="columns">
  <div className="column is-6">
    <div>
    <label style={{fontWeight: 'bold'}}>ขอสินเชื่อ</label>
    </div>
    <div style={{paddingTop: '8px'}}>
    <Field name="loanType" type="select" component="select" style={{width: '100%', height: '34px'}}>
      <option value="0">ขอสินเชื่อด้วยตัวเอง</option>
      <option value="1">เปลี่ยนคนกู้</option>
    </Field>
    </div>
  </div>
</div>
{loanType==='0'? <div></div> : (<div>
<div className="columns is-multiline">
<div className="column is-6">
    <Field
      name="relationType"
      label="ความสัมพันธ์"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="fullName2"
      label="ชื่อ-สกุล"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="birthDate2"
      label="วันเดือนปีเกิด"
      type="text"
      size="is-normal"
      component={renderDatePicker}
      showYearDropdown
    />
  </div>
  <div className="column is-6">
    <Field
      name="citizenId2"
      label="เลขที่บัตรประชาชน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="tel2"
      label="เบอร์โทร"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="email2"
      label="อีเมลล์"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-12">
    <Field
      name="homeAddress2"
      label="ที่อยู่ตามทะเบียนบ้าน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-12">
    <Field
      name="currentAddress2"
      label="ที่อยู่ปัจจุบันที่ติดต่อได้"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
<p className="has-text-grey has-text-weight-bold">
  ข้อมูลการทำงาน :
</p>
<div className="columns is-multiline">
  <div className="column is-6">
    <div>
    <label style={{fontWeight: 'bold'}}>อาชีพ</label>
    </div>
    <div style={{paddingTop: '8px'}}>
    <Field name="favoriteColor" type="select" component="select" style={{width: '100%', height: '34px'}}>
      <option />
      <option value="ff0000">ธุรกิจส่วนตัว</option>
      <option value="00ff00">รายได้ประจำ</option>
    </Field>
    </div>
  </div>
  <div className="column is-6">
    <Field
      name="position2"
      label="ตำแหน่งงาน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="salary2"
      label="รายได้ประจำ / เดือน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="otherincome2"
      label="รายได้อื่นๆ"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
<p className="has-text-grey has-text-weight-bold">
    ค่าใช้จ่าย
</p>
<div className="columns is-multiline">
  <div className="column is-6">
    <Field
      name="homeLoan2"
      label="สินเชื่อบ้าน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="carLoan2"
      label="สินเชื่อรถยนต์"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="creditLoan2"
      label="สินเชื่อบัตรเครดิต"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-6">
    <Field
      name="creditDebt2"
      label="บัตรเครดิต"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
<p className="has-text-grey has-text-weight-bold">
    สถานที่ทำงาน
</p>
<div className="columns is-multiline">
  <div className="column is-5">
    <Field
      name="addressNo2"
      label="เลขที่"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="village2"
      label="หมู่บ้าน/อาคาร"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-3">
    <Field
      name="moo2"
      label="หมู่"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-3">
    <Field
      name="soi2"
      label="ซอย"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-5">
    <Field
      name="road2"
      label="ถนน"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="subDistrict2"
      label="ตำบล/แขวง"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="district2"
      label="อำเภอ/เขต"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="province2"
      label="จังหวัด"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="country2"
      label="ประเทศ"
      type="text"
      size="is-normal"
      component={renderInput}
    />
  </div>
  <div className="column is-4">
    <Field
      name="zipCode2"
      label="รหัสไปรษณีย์"
      type="number"
      size="is-normal"
      component={renderInput}
    />
  </div>
</div>
</div>)}
<div className="column">
  <button
    type="submit"
    className="button is-success"
    disabled={pristine || submitting}
  >
    ยืนยัน
  </button>
</div>
</div>
<div className="column is-half">
  {/* <BankDisplay/> */}
</div>
</div>
</form>)
}

const withReduxForm = reduxForm({
  form: 'EditLoanForm',
  enableReinitialize: true,
})(EditLoanForm);

const selector = formValueSelector('EditLoanForm');

const ConnectWithSelectorForm = connect(state => {
  const loanType = selector(state, 'loanType');

  return {
    loanType,
  };
})(withReduxForm);

export default ConnectWithSelectorForm;