import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import getRmsContractQuery from '../schema/getRmsContract';

class LoanStatus extends Component {
    state = {
        selectContract: '',
        data: [],
        loanStaus: 'ไม่พบข้อมูลการขอสินเชื่อ',
      };

    async handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    
    console.log('data')
    
    this.setState({
        [name]: value,
    });
    }

    async componentDidMount() {

    const data = await this.fetchData();

    const ContractID = this.state.selectContract === ''? data.getRmsCustomerContractMy[0].id
    : this.state.selectContract;

    
    this.setState({
        selectContract:ContractID,
        data: data.getRmsCustomerContractMy, //.filter(o => o.project.id === 'PSE04'),
    });

    }
    
    async fetchData() {
    return this.props.client
    .query({
        query: getRmsContractQuery
    })
    .then(({ data })=> {
        return data;
    });
    }


  render() {
    const allMyContract = this.state.data;

    return (<div className="content">
    <div className="columns">
      <div className="column is-2 has-text-right">
        <strong>โครงการ :</strong>
      </div>
      <div className="column">
        <div className="select">
          <select
            name="selectContract"
            value={this.state.selectContract}
            onChange={e => this.handleChange(e)}
          >
            {allMyContract.map(data => (
              <option value={data.id} key={data.id}>{`${
                data.project.name
              } / ${data.unit.unitNumber}`}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
    <div className="columns" style={{ marginLeft: '40px' }}>{this.state.loanStaus}</div>
  </div>);
  }
}

const withQL = withApollo(LoanStatus);

export default withQL;
