import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
} from "@fortawesome/fontawesome-free-solid";

import EditCrmModal from "./EditCrmModal";

const getCrmContractMyQuery = gql`
  query getRmsCrmContactMy {
    getRmsCrmContactMy {
      id
      customerId
      citizenId
      passportId
      birthdate
      namePrefix {
        id
        text
      }
      firstname
      lastname
      nickname
      gender
      nationality {
        id
        text
      }
      race {
        id
        text
      }

      exigentFirstName
      exigentLastName
      exigentTel
      exigentRelation

      tel1
      tel2
      email

      addressDocument {
        id
        addressNo
        moo
        soi
        village
        road
        subDistrict
        district
        province
        zipCode
        country
      }

      addressRegister {
        id
        addressNo
        moo
        soi
        village
        road
        subDistrict
        district
        province
        zipCode
        country
      }

      addressWork {
        id
        addressNo
        moo
        soi
        village
        road
        subDistrict
        district
        province
        zipCode
        country
      }

      dmFlag
      smsFlag
    }

    getEditCrmStatus {
      id
      type
      editStatus
    }
  }
`;

const DataCell = ({ label, value }) => {
  return (
    <div className="column is-half">
      <div className="columns is-variable is-1">
        <div className="column in-half has-text-right">
          <strong className="has-text-grey">{`${label} :`}</strong>
        </div>
        <div className="column in-half has-text-left has-text-black-bis">
          {value}
        </div>
      </div>
    </div>
  );
};

const Topic = ({ text }) => {
  return (
    <div>
      <h4>{text}</h4>
      <hr />
    </div>
  );
};

export default class CrmDisplay extends Component {
  state = {
    show: false,
  };

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <Query query={getCrmContractMyQuery}>
        {({ data, loading }) => {
          if (loading) return "loading...";

          const {
            customerId,
            firstname,
            lastname,
            namePrefix,
            birthdate,
            gender,
            citizenId,
            passportId,
            race,
            nationality,
            tel1,
            tel2,
            email,
            exigentFirstName,
            exigentLastName,
            exigentRelation,
            exigentTel,
            addressRegister,
            addressDocument,
            addressWork,
          } = data.getRmsCrmContactMy;

          const editStatus =
            data.getEditCrmStatus && data.getEditCrmStatus.editStatus;

          return (
            <div className="content">
              <div className="columns">
                <div className="column has-text-right has-text-justified">
                  {editStatus === "0" ? (
                    <article className="message is-warning">
                      <div className="message-body">
                        <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                        อยู่ในระหว่างแก้ไขข้อมูล
                        หากท่านต้องการแก้ไขเพิ่มเติมกรุณาติดต่อ call center
                        ได้ที่ 020 300 000
                      </div>
                    </article>
                  ) : (
                    <a onClick={() => this.setState({ show: true })}>
                      <FontAwesomeIcon icon={faEdit} /> แก้ไขข้อมูล
                    </a>
                  )}
                </div>
              </div>

              <Topic text="ข้อมูลพื้นฐาน" />
              <div className="columns is-multiline">
                <DataCell
                  label="ชื่อ-นามสกุล"
                  value={`${
                    namePrefix ? namePrefix.text : ""
                  }${firstname} ${lastname}`}
                />
                <DataCell label="รหัสลูกค้า" value={customerId} />
                <DataCell
                  label="วันเกิด"
                  value={moment(birthdate).format("DD/MM/YYYY")}
                />
                <DataCell label="เพศ" value={gender} />
                <DataCell label="เลขที่บัตรประชาชน" value={citizenId} />
                <DataCell label="เลขที่หนังสือเดินทาง" value={passportId} />
                <DataCell label="เชื้อชาติ" value={race && race.text} />
                <DataCell
                  label="สัญชาติ"
                  value={nationality && nationality.text}
                />
              </div>

              <Topic text="ข้อมูลการติดต่อ" />
              <div className="columns is-multiline">
                <DataCell label="เบอร์ติดต่อ(หลัก)" value={tel1} />
                <DataCell label="เบอร์ติดต่อ(สำรอง)" value={tel2} />
                <DataCell label="อีเมลล์" value={email} />
              </div>

              <Topic text="ผู้ติดต่อกรณีฉุกเฉิน" />
              <div className="columns is-multiline">
                <DataCell
                  label="ชื่อผู้ติดต่อ"
                  value={`${exigentFirstName} ${exigentLastName}`}
                />
                <DataCell label="เบอร์โทรศัพท์" value={exigentTel} />
                <DataCell label="ความสัมพันธ์" value={exigentRelation} />
              </div>

              <Topic text="ที่อยู่ตามบัตรประชาชน" />
              <div className="columns is-multiline">
                <DataCell label="เลขที่" value={addressRegister.addressNo} />
                <DataCell
                  label="หมู่บ้าน/อาคาร"
                  value={addressRegister.village}
                />
                <DataCell label="หมู่ที่" value={addressRegister.moo} />
                <DataCell label="ซอย" value={addressRegister.soi} />
                <DataCell label="ถนน" value={addressRegister.road} />
                <DataCell
                  label="ตำบล/แขวง"
                  value={addressRegister.subDistrict}
                />
                <DataCell label="อำเภอ/เขต" value={addressRegister.district} />
                <DataCell label="จังหวัด" value={addressRegister.province} />
                <DataCell label="ประเทศ" value={addressRegister.country} />
                <DataCell
                  label="รหัสไปรษณีย์"
                  value={addressRegister.zipCode}
                />
              </div>

              <Topic text="ที่อยู่ที่สามารถติดต่อได้" />
              <div className="columns is-multiline">
                <DataCell label="เลขที่" value={addressDocument.addressNo} />
                <DataCell
                  label="หมู่บ้าน/อาคาร"
                  value={addressDocument.village}
                />
                <DataCell label="หมู่ที่" value={addressDocument.moo} />
                <DataCell label="ซอย" value={addressDocument.soi} />
                <DataCell label="ถนน" value={addressDocument.road} />
                <DataCell
                  label="ตำบล/แขวง"
                  value={addressDocument.subDistrict}
                />
                <DataCell label="อำเภอ/เขต" value={addressDocument.district} />
                <DataCell label="จังหวัด" value={addressDocument.province} />
                <DataCell label="ประเทศ" value={addressDocument.country} />
                <DataCell
                  label="รหัสไปรษณีย์"
                  value={addressDocument.zipCode}
                />
              </div>

              <Topic text="ที่อยู่ที่ทำงาน" />
              <div className="columns is-multiline">
                <DataCell label="เลขที่" value={addressWork.addressNo} />
                <DataCell label="หมู่บ้าน/อาคาร" value={addressWork.village} />
                <DataCell label="หมู่ที่" value={addressWork.moo} />
                <DataCell label="ซอย" value={addressWork.soi} />
                <DataCell label="ถนน" value={addressWork.road} />
                <DataCell label="ตำบล/แขวง" value={addressWork.subDistrict} />
                <DataCell label="อำเภอ/เขต" value={addressWork.district} />
                <DataCell label="จังหวัด" value={addressWork.province} />
                <DataCell label="ประเทศ" value={addressWork.country} />
                <DataCell label="รหัสไปรษณีย์" value={addressWork.zipCode} />
              </div>

              <EditCrmModal
                show={this.state.show}
                handleClose={() => this.handleClose()}
                customerData={data.getRmsCrmContactMy}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}
