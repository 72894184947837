import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { SubmissionError } from 'redux-form';
import loginMutate from 'schema/loginMutate';
import { login as loginAction } from 'reducers/user';
import LoginForm from './LoginForm';
import DescriptionBox from './DescriptionBox';
import { PasswordSuggestion } from './PasswordSuggestion';

const EmailTag = styled.a`color: #4472c4;`;

class LoginLayout extends Component {
  handleSubmit (values) {
    const isMm = moment.isMoment(moment(values.password, 'DDMMMYYYY'))
    if (isMm) {
      return this.props
        .mutate({
          variables: { username: values.username, password: moment(values.password, 'DDMMMYYYY').format('DDMMYYYY') },
        })
        .then(response => {
          const { token, refreshToken } = response.data.customerLogin;
          this.props.updateUserData(token, refreshToken);
          this.props.gotoHome();
        })
        .catch(e => {
          console.log(e);
          throw new SubmissionError({
            _error: 'Login failed!',
          });
        });
    }

    return  new Promise(() => {
      throw new SubmissionError({
            _error: 'Login failed!',
          });
    })

  };

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <DescriptionBox />
          </div>
          <div className="column">
            <LoginForm onSubmit={values => this.handleSubmit(values)} />
            <PasswordSuggestion />
            <div className="content is-small">
              <p>
                * ท่านสามารถตรวจสอบข้อมูลการจ่ายงวดดาวน์ได้เฉพาะโครงการใหม่
                (ไม่นับรวมโครงการพร้อมอยู่)
              </p>
              <p>
                ** หากท่านไม่สามารถเข้าใช้งานได้กรุณาติดต่อหรือแจ้งกลับที่ Call Center 020 300 000 กด 1
              </p>
              <p>
                If you have any issues regarding the usage of this system,
                please contact Call Center 020 300 000 press 1
                (Monday-Friday 9:00 – 18:00)
              </p>
              <p>
                ご不明な点等がございましたら、+66-84-4270099 又は{' '}
                <EmailTag href="wisanee.s@origin.co.th">
                  wisanee.s@origin.co.th
                </EmailTag>{' '}
                までご連絡くださいませ。（月曜日～金曜日０９：００～１８：００まで）
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserData: (token, refreshToken) => {
      dispatch(loginAction(token, refreshToken));
    },
  };
};

const loginMutateWithData = graphql(loginMutate)(LoginLayout);

export default connect(null, mapDispatchToProps)(loginMutateWithData);
