import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainLayout from 'components/layout/MainLayout';
import PaymentLayout from '../rmsContract/PaymentLayout';

class Home extends Component {
  state = {
    currentForm: 'login',
  };

  changeForm(form) {
    this.setState({ currentForm: form });
  }

  render() {
    return (
      <MainLayout>
        <PaymentLayout changeForm={form => this.changeForm(form)} />
      </MainLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(Home);
