import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderInput } from 'components/reduxForm/normalForm';
import { required } from 'components/reduxForm/validate';

const Topic = ({ text }) => {
  return (
    <div>
      <h4>{text}</h4>
      <hr />
    </div>
  );
};

const EditCrmForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  handleClose,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Topic text="เบอร์ติดต่อ" />
      <div className="columns">
        <div className="column in-half">
          <Field
            name="tel1"
            label="เบอร์โทรศัพท์(หลัก)"
            type="number"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column in-half">
          <Field
            name="tel2"
            label="เบอร์โทรศัพท์(สำรอง)"
            type="number"
            size="is-normal"
            component={renderInput}
          />
        </div>
      </div>

      <Topic text="ที่อยู่ที่สามารถติดต่อได้" />
      <div className="columns is-multiline">
        <div className="column is-4">
          <Field
            name="addressNo"
            label="เลขที่"
            type="text"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column is-5">
          <Field
            name="village"
            label="หมู่บ้าน/อาคาร"
            type="text"
            size="is-normal"
            component={renderInput}
          />
        </div>
        <div className="column is-3">
          <Field
            name="moo"
            label="หมู่"
            type="text"
            size="is-normal"
            component={renderInput}
          />
        </div>
        <div className="column is-3">
          <Field
            name="soi"
            label="ซอย"
            type="text"
            size="is-normal"
            component={renderInput}
          />
        </div>
        <div className="column is-5">
          <Field
            name="road"
            label="ถนน"
            type="text"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column is-4">
          <Field
            name="subDistrict"
            label="ตำบล/แขวง"
            type="text"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column is-4">
          <Field
            name="district"
            label="อำเภอ/เขต"
            type="text"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column is-4">
          <Field
            name="province"
            label="จังหวัด"
            type="text"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column is-4">
          <Field
            name="country"
            label="ประเทศ"
            type="text"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
        <div className="column is-4">
          <Field
            name="zipCode"
            label="รหัสไปรษณีย์"
            type="number"
            size="is-normal"
            component={renderInput}
            validate={[required]}
          />
        </div>
      </div>
      {error && (
        <article className="message is-danger">
          <div className="message-body">{error}</div>
        </article>
      )}

      <div className="field is-grouped is-pulled-right">
        <div className="control">
          <button
            className="button"
            type="button"
            onClick={() => handleClose(2)}
          >
            ยกเลิก
          </button>
        </div>
        <div className="control">
          <button
            type="submit"
            className="button is-success"
            disabled={pristine || submitting}
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </form>
  );
};

const withReduxForm = reduxForm({
  form: 'EditCrmForm',
  enableReinitialize: true,
})(EditCrmForm);

export default withReduxForm;
