import gql from 'graphql-tag';

export default gql`
query getRmsContract {
  getRmsCustomerContractMy {
    id
    customer {
      id
      memberId
    }
    project {
      id
      name
    }
    unit {
      id
      unitNumber
    }
    recurring {
      id
      directType
    }
  }
}
`;
