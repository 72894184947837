import React, { Component } from 'react';
import styled from 'styled-components';

const ThaiWord = styled.span`
  color: ${props => props.color || '#666'};
  font-weight: bold;
`;

const EngWord = styled.span`
  color: #4472c4;
  font-weight: bold;
`;

const JapanWord = styled.span`
  color: #006600;
  font-weight: bold;
`;

class DescriptionBox extends Component {
  render() {
    return (
      <div className="content">
        <p>
          <ThaiWord>สำหรับท่านผู้เป็นสมาชิกออริจิ้น ท่านสามารถตรวจสอบรายการชำระเงิน</ThaiWord>
          <br />
          <ThaiWord color="#ea153c">และดาวน์โหลดเอกสารเกี่ยวกับการพักอาศัยของท่านได้ โดย</ThaiWord>
          <br />
          <EngWord>
            Our customers are able to check your payment details by using our
            payment system; please follow our step-by-step procedure below,
          </EngWord>
          <br />
          <JapanWord>
            オリジンプロパティーのお客様は、プロジェクトのお支払い情報を確認することができます。
          </JapanWord>
        </p>
        <p>
          <ThaiWord>
            1. กรอกเลขที่บัตรประชาชน (13 หลัก) ในช่อง ID no./Passport no.
          </ThaiWord>
          <br />
          <EngWord>1. Enter your passport number as your username</EngWord>
          <br />
          <JapanWord>１.パスポート番号をご入力ください。</JapanWord>
        </p>
        <p>
          <ThaiWord>
            2. กรอกวัน เดือน ปี เกิดของท่าน โดยปีเกิดเป็นคริสตศักราช (ค.ศ.)
            ในช่อง Password
          </ThaiWord>
          <br />
          <EngWord>2. Enter your birth date as your password</EngWord>
          <br />
          <JapanWord>
            2.パスワードは、お客様の西暦誕生日をご入力ください。
          </JapanWord>
        </p>
        <p>
          <ThaiWord>3. กดปุ่ม Log in</ThaiWord>
          <br />
          <EngWord>3. To continue, please click on the log in button.</EngWord>
          <br />
          <JapanWord>3.ログオンボタンをクリックしてください。</JapanWord>
        </p>
      </div>
    );
  }
}

export default DescriptionBox;
