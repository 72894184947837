import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginLayout from './components/LoginLayout';
import version from '../../version';

class LoginPage extends Component {
  state = {
    currentForm: 'login',
  };

  changeForm(form) {
    this.setState({ currentForm: form });
  }

  gotoHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <section
          className="section is-small is-paddingless"
          style={{ backgroundColor: '#241F20', marginBottom: '32px' }}
        >
          <div className="container-fluid" style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/head-logo.png`} alt="logo" />
          </div>
        </section>
        <LoginLayout gotoHome={() => this.gotoHome()} changeForm={form => this.changeForm(form)} />
        <section
          style={{
            backgroundColor: 'rgb(50, 50, 50)',
            marginTop: '89px',
            color: 'rgb(255, 255, 255)',
            padding: '15px',
          }}
        >
          <small>
            © Origin Public Company Limited. 2017 All rights reserved. Version
            {version}
          </small>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(LoginPage);
