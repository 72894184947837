import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import classNames from 'classnames';

const required = value => (value ? undefined : 'Required');

const PasswordDesc = styled.p`
  color: #EC4C63;
`;

const renderField = ({
  input,
  label,
  type,
  info,
  meta: { touched, error, warning },
}) => {
  const inputClass = classNames({
    input: true,
    'is-danger': touched && error !== undefined ? true : false,
  });

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <input className={inputClass} {...input} type={type} />
      </div>
      {info &&
      <PasswordDesc className="help blink" style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {info}
      </PasswordDesc>}
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </div>
  );
};

class LoginForm extends Component {
  render() {
    const { handleSubmit, submitting, error } = this.props;

    return (
      <div className="box has-background-grey-lighter">
        <form onSubmit={handleSubmit}>
          <Field
            name="username"
            type="text"
            component={renderField}
            label="ID no. / Passport no."
            validate={[required]}
          />
          <Field
            name="password"
            type="password"
            component={renderField}
            label="Password"
            validate={[required]}
          />

          {error && (
          <div className="notification is-danger" style={{ backgroundColor: '#F2DEDE', color: '#A94442'}}>{error}</div>
        )}

          <div className="field is-grouped">
            <div className="control">
              <button
                type="submit"
                className="button is-primary"
                disabled={submitting}
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'formLogin',
})(LoginForm);
