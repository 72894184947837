import React, { Component } from 'react';
import HeadNavbar from '../HeadNavbar';
import version from '../../version';
class MainLayout extends Component {
  render() {
    return (
      <div>
        <section
          className="section is-small is-paddingless"
          style={{ backgroundColor: '#241F20' }}
        >
          <div className="container-fluid" style={{ textAlign: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/head-logo.png`} alt="logo" />
          </div>
        </section>
        <HeadNavbar />
        <section className="section">{this.props.children}</section>
        <section
          style={{
            backgroundColor: 'rgb(50, 50, 50)',
            marginTop: '20px',
            color: 'rgb(255, 255, 255)',
            padding: '15px',
          }}
        >
          <small>
            © Origin Public Company Limited. 2017 All rights reserved. Version
            {version}
          </small>
        </section>
      </div>
    );
  }
}

export default MainLayout;
