import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import classNames from "classnames";
import { Icon, Table } from "antd";

const required = (value) => (value ? undefined : "Required");
const PasswordDesc = styled.p`
  color: #ec4c63;
`;
const { Column } = Table;

const renderField = ({
  input,
  label,
  type,
  info,
  placeholder,
  meta: { touched, error, warning },
}) => {
  const inputClass = classNames({
    input: true,
    "is-danger": touched && error !== undefined ? true : false,
  });

  return (
    <div className="field">
      <label
        className="label"
        style={{ color: "#5a5a5a", fontWeight: "normal" }}
      >
        {label}
      </label>
      <div className="control">
        <input
          className={inputClass}
          placeholder={placeholder}
          {...input}
          type={type}
        />
      </div>
      {info && (
        <PasswordDesc
          className="help blink"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {info}
        </PasswordDesc>
      )}
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </div>
  );
};

const CaculateForm = ({
  handleSubmit,
  resultRepaymentmo,
  resultLoanAmount,
  resultPactRepaymentmo,
  resultDebtToIncomeRatio,
  resultDataDsr,
  resultContractPrice,
  mapInstallment,
}) => {
  const clear = () => {
    window.location.reload();
  };

  const formatAmount = (val) => {
    if (!val) return;
    const re = val.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return re;
  };
  const normalizeAmount = (val) => {
    if (!val) return;
    const re = val.replace(/[^\d]/g, "");
    return re;
  };

  // console.log("resultDataDrs.drs-->", resultDataDsr.dsr);
  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="box">
            <div className="title-nav" style={{ color: "black" }}>
              คำนวณความสามารถในการผ่อนชำระ
            </div>
            <form onSubmit={handleSubmit} name="caculate">
              <Field
                name="income"
                type="text"
                component={renderField}
                label="รายได้รับรวมต่อเดือน (บาท)"
                placeholder="กรุณากรอกรายได้ทั้งหมด"
                validate={[required]}
                format={formatAmount}
                normalize={normalizeAmount}
              />
              <Field
                name="debt"
                type="text"
                component={renderField}
                label="ภาระหนี้จ่ายต่อเดือน (บาท)"
                placeholder="กรุณากรอกหนี้สินทั้งหมด"
                validate={[required]}
                format={formatAmount}
                normalize={normalizeAmount}
                // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
              />
              <Field
                name="ContractPrice"
                type="text"
                component={renderField}
                label="ราคาหน้าสัญญา (บาท)"
                placeholder="กรุณากรอกราคาหน้าสัญญา"
                format={formatAmount}
                normalize={normalizeAmount}
              />

              <div className="columns">
                <div className="column">
                  <div
                    className="control"
                    style={{
                      textAlign: "center",
                      margin: 1,
                    }}
                  >
                    <button
                      style={{
                        marginTop: 15,
                        width: "100%",
                        fontFamily: "Mitr",
                      }}
                      type="button"
                      className="button"
                      onClick={clear}
                    >
                      ล้างข้อมูล
                    </button>
                  </div>
                </div>
                <div className="column">
                  <div
                    className="control"
                    style={{
                      textAlign: "center",
                      margin: 1,
                    }}
                  >
                    <button type="submit" className="button button1">
                      คำนวณ
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="column">
          <div className="box" style={{ backgroundColor: "rgb(243, 155, 24)" }}>
            <div
              style={{
                fontSize: 36,
                fontFamily: "Mitr",
                color: "#5a5a5a",
                textAlign: "center",
              }}
            >
              ประมาณการคำนวณวงเงินกู้และค่างวดผ่อนชำระ
            </div>

            <div className="result">
              <div className="columns">
                <div className="column">
                  {resultLoanAmount ? resultLoanAmount + " บาท" : "0 บาท"}
                  <div
                    style={{
                      color: "#8f8f8f",
                      fontSize: 16,
                      fontFamily: "Kodchasan",
                    }}
                  >
                    กู้ได้สูงสุดประมาณ (บาท)
                  </div>
                </div>
                {resultDebtToIncomeRatio && <div className="decoration"></div>}
                {resultDebtToIncomeRatio && (
                  <div
                    className="column"
                    style={{
                      color:
                        resultDebtToIncomeRatio <= resultDataDsr.dsr
                          ? "#2E8B57"
                          : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5
                          ? "#FF8C00"
                          : "#FF0000",
                      fontSize: 16,
                      fontFamily: "Kodchasan",
                    }}
                  >
                    {/* {resultRepaymentmo ? resultRepaymentmo + " บาท" : "0 บาท"}
                  <div
                    style={{
                      color: "#8f8f8f",
                      fontSize: 16,
                      fontFamily: "Kodchasan",
                    }}
                  >
                    ประมาณการค่างวดของวงเงินกู้ */}

                    {resultDebtToIncomeRatio <= resultDataDsr.dsr ? (
                      <Icon type="smile" style={{ fontSize: "48px" }} />
                    ) : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5 ? (
                      <Icon type="meh" style={{ fontSize: "48px" }} />
                    ) : (
                      <Icon type="frown" style={{ fontSize: "48px" }} />
                    )}
                    <div
                      style={{
                        color:
                          resultDebtToIncomeRatio <= resultDataDsr.dsr
                            ? "#2E8B57"
                            : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5
                            ? "#FF8C00"
                            : "#FF0000",
                        fontSize: 16,
                        fontFamily: "Kodchasan",
                      }}
                    >
                      {resultDebtToIncomeRatio <= resultDataDsr.dsr
                        ? "ผ่าน"
                        : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5
                        ? "หาคนกู้ร่วม หรือ ลดภาระหนี้"
                        : "รายได้ไม่เพียงพอต่อการกู้ครั้งนี้"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {resultDataDsr.installment && (
            <div className="where" style={{ marginBottom: 20 }}>
              {`ผ่อนล้านละ ${resultDataDsr.installment}`}
            </div>
          )}

          <div className="where">
            เงื่อนไข:
            <p>1. การผ่อนชำระหนี้นานสูงสุด 30ปี</p>
            <p>
              2.
              การพิจารณาอนุมัติสินเชื่อเป็นไปตามหลักเกณฑ์และเงื่อนไขที่แต่ละธนาคารกำหนด
            </p>
          </div>

          {resultContractPrice && resultContractPrice !== 1 && (
            <div
              className="box"
              style={{ backgroundColor: "rgb(243, 155, 24)", marginTop: 20 }}
            >
              <div
                style={{
                  fontSize: 36,
                  fontFamily: "Mitr",
                  color: "#5a5a5a",
                  textAlign: "center",
                }}
              >
                ตารางคำนวนค่างวดตามระยะเวลา(ปี)
              </div>

              <div className="result">
                <div className="columns">
                  <Table
                    style={{ textAlign: "center" }}
                    dataSource={[mapInstallment]}
                    pagination={false}
                  >
                    <Column
                      title="ระยะเวลาผ่านชำระ"
                      width={200}
                      align="center"
                      render={(text) => "ประมาณการค่างวด"}
                    />
                    <Column
                      title="20 ปี"
                      dataIndex="20"
                      key="20"
                      width={100}
                      align="center"
                    />
                    <Column
                      title="25 ปี"
                      dataIndex="25"
                      key="25"
                      width={100}
                      align="center"
                    />
                    <Column
                      title="30 ปี"
                      dataIndex="30"
                      key="30"
                      width={100}
                      align="center"
                    />
                    <Column
                      title="35 ปี"
                      dataIndex="35"
                      key="35"
                      width={100}
                      align="center"
                    />
                  </Table>
                  {/* <div className="column">
                    {resultPactRepaymentmo && resultContractPrice !== 0
                      ? resultPactRepaymentmo + " บาท"
                      : "0 บาท"}
                    <div
                      style={{
                        color: "#8f8f8f",
                        fontSize: 16,
                        fontFamily: "Kodchasan",
                      }}
                    >
                      จำนวนเงินผ่อนชำระ (บาท/งวด)
                    </div>
                  </div>
                  <div className="decoration"></div>
                  <div
                    className="column"
                    style={{
                      color:
                        resultDebtToIncomeRatio <= resultDataDsr.dsr
                          ? "#2E8B57"
                          : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5
                          ? "#FF8C00"
                          : "#FF0000",
                    }}
                  >
                    {resultDebtToIncomeRatio <= resultDataDsr.dsr ? (
                      <Icon type="smile" style={{ fontSize: "48px" }} />
                    ) : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5 ? (
                      <Icon type="meh" style={{ fontSize: "48px" }} />
                    ) : (
                      <Icon type="frown" style={{ fontSize: "48px" }} />
                    )}
                    <div
                      style={{
                        color: "#8f8f8f",
                        fontSize: 16,
                        fontFamily: "Kodchasan",
                      }}
                    >
                      {resultDebtToIncomeRatio <= resultDataDsr.dsr
                        ? "ผ่าน"
                        : resultDebtToIncomeRatio <= resultDataDsr.dsr + 5
                        ? "หาคนกู้ร่วม หรือ ลดภาระหนี้"
                        : "รายได้ไม่เพียงพอต่อการกู้ครั้งนี้"}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          {resultContractPrice && resultContractPrice !== 1 && (
            <div className="where">
              <strong>หมายเหตุ </strong>{" "}
              ตารางการคำนวนประมาณการวงเงินสินเชื่อบ้านดังกล่าวเป็นการประมาณโดยอัตราดอกเบี้ย
              4.65% ต่อปี
              ซึ่งรายละเอียดวงเงินระยะเวลาผ่อนและค่างวดที่แท้จริงนั้นธนาคารจะแจ้งให้ลูกค้าทราบเมื่อได้รับการอนุมัติสินเชื่อดังกล่าว
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "CaculateForm",
})(CaculateForm);
