import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { client } from 'common/config';
import store from './store';
import './App.css';
import Routes from './Routes';

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
