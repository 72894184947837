import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withApollo, Query } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser } from '@fortawesome/fontawesome-free-solid';
import getRmsContractQuery from '../pages/cs/schema/getRmsContract';

import { clearAuth } from 'reducers/user';

class HeadNavbar extends Component {
  state = {
    showNav: '',
  };

  handleNav() {
    if (this.state.showNav === '') {
      this.setState({ showNav: 'is-active' });
    } else {
      this.setState({ showNav: '' });
    }
  }

  render() {
    const { showNav } = this.state;
    return (
      <nav className="navbar has-shadow is-spaced is-light">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <span>
                <FontAwesomeIcon icon={faHome} style={{ color: '#F85C4A' }} />{' '}
                Home
              </span>
            </Link>
            <div
              role="button"
              className={`navbar-burger burger ${showNav}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="nav-menu"
              onClick={() => this.handleNav()}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </div>
          </div>
          <div id="nav-menu" className={`navbar-menu ${showNav}`}>
            <div className="navbar-start" />
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  <span>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: '#2C68D7' }}
                    />{' '}
                    คุณ
                    {this.props.user && this.props.user.username}
                  </span>
                </a>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/info">
                    แก้ไขข้อมูลส่วนตัว
                  </Link>
                  <Query query={getRmsContractQuery}>
                  {({ data, loading }) => {
                    if (loading) return 'loading...';
                    const canLoanOnline = data.getRmsCustomerContractMy.filter(o=>o.project.id==='KEN08')
                    return ( canLoanOnline.length > 0?
                      <Link className="navbar-item" to="/cs">
                        บริการขอสินเชื่อ
                      </Link> : ''
                    )
                  }}
                  </Query>
                  <a
                    className="navbar-item"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.origin.co.th/directdebitform/"
                  >
                    สมัครหักบัญชี/สมัครหักบัตรเครดิต
                  </a>
                  <a
                    className="navbar-item"
                    onClick={() => {
                      this.props.client.resetStore();
                      this.props.clearAuth();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearAuth: searchText => {
      dispatch(clearAuth());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApollo(HeadNavbar));
