import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import EditRemLayout from './EditRemLayout';

const getRmsContractQuery = gql`
  query getRmsContract {
    getRmsCustomerContractMy {
      id
      customer {
        id
        memberId
      }
      project {
        id
        name
      }
      unit {
        id
        unitNumber
      }
      recurring {
        id
        directType
      }
    }
  }
`;

class RemDisplay extends Component {
  state = {
    selectContract: '',
    data: [],
  };

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.client
      .query({
        query: getRmsContractQuery,
      })
      .then(({ data }) => {
        this.setState({
          selectContract:
            this.state.selectContract === ''
              ? data.getRmsCustomerContractMy[0].id
              : this.state.selectContract,
          data: data.getRmsCustomerContractMy,
        });
      });
  }

  render() {
    const allMyContract = this.state.data;
    return (
      <div className="content">
        <div className="columns">
          <div className="column is-2 has-text-right">
            <strong>โครงการ :</strong>
          </div>
          <div className="column">
            <div className="select">
              <select
                name="selectContract"
                value={this.state.selectContract}
                onChange={e => this.handleChange(e)}
              >
                {allMyContract.map(data => (
                  <option value={data.id} key={data.id}>{`${
                    data.project.name
                  } / ${data.unit.unitNumber}`}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <EditRemLayout id={this.state.selectContract} />
      </div>
    );
  }
}

const withQL = withApollo(graphql(getRmsContractQuery)(RemDisplay));

export default withQL;
