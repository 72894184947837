import React, { Component } from 'react';
import MainLayout from 'components/layout/MainLayout';
import InfoMenu from './components/Menu';
import CrmDisplay from './components/CrmDisplay';
import RemDisplay from './components/RemDisplay';

export default class InfoSetting extends Component {
  state = {
    no: '1',
  };

  handleChangeMenu(no) {
    this.setState({ no });
  }

  checkDisplay(no) {
    switch (no) {
      case '1':
        return <CrmDisplay />;
      case '2':
        return <RemDisplay />;
      default:
        break;
    }
  }

  render() {
    const { no } = this.state;
    return (
      <MainLayout>
        <div className="container">
          <div className="columns">
            <div className="column is-one-fifth">
              <InfoMenu
                no={this.state.no}
                handleChangeMenu={no => this.handleChangeMenu(no)}
              />
            </div>
            <div className="column">{this.checkDisplay(no)}</div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
