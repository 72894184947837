import React, { Component } from 'react';

export default class InfoMenu extends Component {
  render() {
    const { no, handleChangeMenu } = this.props;
    return (
      <aside className="menu">
        <ul className="menu-list">
          <li>
            <a
              className={no === '1' ? 'is-active' : ''}
              onClick={() => handleChangeMenu('1')}
            >
              ข้อมูลส่วนตัว
            </a>
          </li>
          <li>
            <a
              className={no === '2' ? 'is-active' : ''}
              onClick={() => handleChangeMenu('2')}
            >
              ข้อมูลรายสัญญา
            </a>
          </li>
        </ul>
      </aside>
    );
  }
}
