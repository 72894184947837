import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import { userIsAuthenticated } from "./auth";
import withTracker from "./withTracker";

import LoginPage from "pages/login/LoginPage";
import Home from "pages/home";
import InfoSetting from "pages/info";
import CS from "pages/cs";
// import Caculate from "pages/caculate/CaculateLayout";
import Calculater from "pages/calculater";
import Payment from "pages/payment";

const history = createHistory();

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/login" component={withTracker(LoginPage)} />
      <Route
        exact
        path="/"
        component={userIsAuthenticated(withTracker(Home))}
      />
      <Route
        exact
        path="/info"
        component={userIsAuthenticated(withTracker(InfoSetting))}
      />
      <Route
        exact
        path="/cs"
        component={userIsAuthenticated(withTracker(CS))}
      />
      <Route
        exact
        path="/caculate"
        component={withTracker(Calculater)}
      />
      <Route
        exact
        path="/originconnect/payment/:amount/:paymentMethods/:projectId"
        component={withTracker(Payment)}
      />
      <Route
        exact
        path="/originconnect/payment/:amount/:paymentMethods/:projectId/:orderId"
        component={withTracker(Payment)}
      />
      <Route component={NoMatch} />
    </Switch>
  </Router>
);
